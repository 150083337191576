import APP_CONFIG from "@/apps/core/modules/config.js";
import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "kelompok";
const modelPath = `/anggota/tag/`;

class Kelompok extends ModelBase {
  constructor() {
    let model = {
      id: null,
      nama: "",
      kelompok_set: [],
      pspd_set: []
    };
    let requiredFields = ["nama", "pspd_set", "kelompok_set"];
    super(modelName, model, requiredFields, [], modelPath);
  }

  reset() {
    super.reset();
    let apiURL = `${APP_CONFIG.baseAPIURL}${modelPath}`;
    this.setApiURL(apiURL);
  }

  getEmptyModel() {
    this.observables.kelompok.kelompok_set = [];
    return this.observables.kelompok;
  }

  validateSet() {
    this.resetErrors();
    if (this.observables.kelompok.pspd_set.length == 0 && this.observables.kelompok.kelompok_set.length == 0) {
      this.observables.errorMap.non_field_errors = "Salah satu harus diisi.";
    }
    this.calcValidity();
  }

  initObservables() {
    super.initObservables();
    this.observables.prefix = {};
  }

  getLoadData(data) {
    let kelompok = super.getLoadData(data);
    kelompok.kelompok_set = [];
    return kelompok;
  }

  getPayload() {
    let payLoadData = super.getPayload();
    let data_set = [];
    if (this.observables.kelompok.pspd_set.length > 0) {
      data_set = this.observables.kelompok.pspd_set.map(obj => obj.id);
    }
    for (let kelompok of this.observables.kelompok_pspd_map) {
      let pspdIdList = kelompok.pspdList.map(obj => obj.id);
      for (let pspd_id of pspdIdList) {
        data_set.push(pspd_id);
      }
    }
    let data = {"nama": payLoadData.nama, "pspd_set": data_set};
    return data;
  }
}

export default Kelompok;